import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["inputContainer", "list", "item", "reset"];

  update(event) {
    if (event.target.value === "") {
      this.reset();
      return;
    }

    const searchValue = event.target.value.toLowerCase();
    this.resetTarget.classList.remove("hidden");

    this.itemTargets.forEach((itemEl) => {
      const nameValue = (itemEl.dataset.searchFilterName || "").toLowerCase();
      const emailValue = (itemEl.dataset.searchFilterEmail || "").toLowerCase();

      const combinedData = [nameValue, emailValue].join(" ");

      const isMatch = searchValue
        .split(" ")
        .every((term) => combinedData.includes(term));
      itemEl.style.display = isMatch ? "" : "none";
    });
  }

  reset() {
    this.inputContainerTarget.querySelector("input").value = "";
    this.resetTarget.classList.add("hidden");
    this.itemTargets.forEach((itemEl) => {
      itemEl.style.display = "";
    });
  }
}
