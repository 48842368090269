import { Controller } from "@hotwired/stimulus";
import tippy from "tippy.js";

export default class extends Controller {
  connect() {
    this.tooltip = tippy(this.element, {
      content: this.element.dataset.tippyContent || "",
      placement: "top",
      animation: "fade",
      theme: "light-border",
      trigger: "manual",
      arrow: true,
      allowHTML: true,
    });
  }

  showTooltip() {
    if (this.tooltip) {
      this.tooltip.show();
    }
  }

  hideTooltip() {
    if (this.tooltip) {
      this.tooltip.hide();
    }
  }
}
