import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button", "knob", "hiddenInput"];

  toggle() {
    const currentState = this.element.dataset.state; // "checked" or "unchecked"
    const newState = currentState === "checked" ? "unchecked" : "checked";

    this.hiddenInputTarget.value = newState;
    this.buttonTarget.dataset.state = newState;
    this.knobTarget.dataset.state = newState;
    this.element.dataset.state = newState;

    this.buttonTarget.setAttribute(
      "aria-checked",
      newState === "checked" ? "true" : "false"
    );
  }
}
