import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["csvImport", "leadObjectType", "personas", "triggerConditions"];

  connect() {
    this.updateState();
  }

  updateState() {
    const form = this.element.closest("form");

    // hide everything by default
    this.csvImportTarget.classList.add("hidden");
    this.leadObjectTypeTarget.classList.add("hidden");
    this.personasTarget.classList.add("hidden");
    this.triggerConditionsTarget.classList.add("hidden");

    // manual import
    if (form.querySelector("#playbook_trigger_source_manual_import").checked) {
      this.csvImportTarget.classList.remove("hidden");
      this.leadObjectTypeTarget.classList.remove("hidden");
      this.personasTarget.classList.remove("hidden");
      return;
    }

    // team network
    if (form.querySelector("#playbook_trigger_source_team_network").checked) {
      this.personasTarget.classList.remove("hidden");
      return;
    }

    // rule builder
    if (form.querySelector("#playbook_trigger_source_rule_builder").checked) {
      this.leadObjectTypeTarget.classList.remove("hidden");
      this.triggerConditionsTarget.classList.remove("hidden");
      return;
    }
  }
}
